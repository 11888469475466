"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _i18next = _interopRequireDefault(require("i18next"));

var _i18nextXhrBackend = _interopRequireDefault(require("i18next-xhr-backend"));

var _i18nextBrowserLanguagedetector = _interopRequireDefault(require("i18next-browser-languagedetector"));

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/* eslint-disable import/named */
var resources;

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  var _require = require('./locales'),
      ptBR = _require.ptBR;

  resources = {
    'pt-BR': ptBR
  };
}

var config = {
  resources: resources,
  lng: 'pt-BR',
  fallbackLng: 'pt-BR',
  // have a common namespace used around the full app
  ns: ['commons'],
  defaultNS: 'commons',
  debug: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false // not needed for react!!

  },
  react: {
    wait: true
  },
  // default i18n loads languages per language set, as we loaded pt-BR it also loaded pt languages
  // load: currentOnly only loads selected language
  load: 'currentOnly'
};

if (process.env.NODE_ENV === 'production') {
  config.backend = {
    loadPath: "".concat(process.env.REACT_APP_HOMEPAGE, "locales/{{lng}}/{{ns}}.json")
  };
}

_i18next["default"].use(_i18nextXhrBackend["default"]).use(_i18nextBrowserLanguagedetector["default"]).use(_reactI18next.reactI18nextModule).init(config);

var _default = _i18next["default"];
exports["default"] = _default;