/* eslint-disable no-const-assign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './styles.scss';

const PasswordStrength = (props) => {
  const { t, password } = props;
  const [parts] = useState(['', '', '', '', '', '', '', '', '', '']);
  const [score, setScore] = useState(undefined);

  const getColor = (i) => {
    if (i <= 0 || score === undefined) return '#E5E5E5';
    if (i < 5 && score === 'weak') return '#ce3b3b';
    if (i < 7 && score === 'medium') return '#c5d836';
    if (i < 11 && score === 'strong') return '#5c7fce';

    return '#E5E5E5';
  };

  const scorePassword = () => {
    let somaScore = 0;
    if (!password) return undefined;
    if (password.length >= 6) {
      somaScore = 60;
    }
    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    };

    let variationCount = 0;
    _.forEach(variations, (variation) => {
      variationCount += (variation === true) ? 1 : 0;
    });
    somaScore += (variationCount - 1) * 10;
    setScore(somaScore);

    if (somaScore > 80) {
      return 'strong';
    }
    if (somaScore > 60) {
      return 'medium';
    }

    return 'weak';
  };

  useEffect((nextProps) => { setScore(scorePassword(nextProps)); });
  const generateKey = index => `${index}_${new Date().getTime()}`;

  return (
    <div className="password-strength-component">
      <p className="strengthlabel">
        {score && t(`autenticacao:passwordStrength.${score}`)}
      </p>
      <div className="scorecontainer">
        {parts.map((part, index) => (
          <div className="item" key={generateKey(index)} style={{ backgroundColor: getColor(index + 1) }}>{part}</div>
        ))}

      </div>
    </div>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default PasswordStrength;
