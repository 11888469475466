export const CONFIRMAR_CADASTRO = 'CONFIRMAR_CADASTRO';
export const CONFIRMAR_CADASTRO_REQUISICAO = 'CONFIRMAR_CADASTRO_REQUISICAO';
export const CONFIRMAR_CADASTRO_SUCESSO = 'CONFIRMAR_CADASTRO_SUCESSO';
export const CONFIRMAR_CADASTRO_ERRO = 'CONFIRMAR_CADASTRO_ERRO';

export const REENVIAR_CONFIRMACAO_CADASTRO = 'REENVIAR_CONFIRMACAO_CADASTRO';
export const REENVIAR_CONFIRMACAO_CADASTRO_REQUISICAO = 'REENVIAR_CONFIRMACAO_CADASTRO_REQUISICAO';
export const REENVIAR_CONFIRMACAO_CADASTRO_SUCESSO = 'REENVIAR_CONFIRMACAO_CADASTRO_SUCESSO';
export const REENVIAR_CONFIRMACAO_CADASTRO_ERRO = 'REENVIAR_CONFIRMACAO_CADASTRO_ERRO';

export const NOME_USUARIO_DISPONIVEL = 'NOME_USUARIO_DISPONIVEL';
export const NOME_USUARIO_DISPONIVEL_REQUISICAO = 'NOME_USUARIO_DISPONIVEL_REQUISICAO';
export const NOME_USUARIO_DISPONIVEL_SUCESSO = 'NOME_USUARIO_DISPONIVEL_SUCESSO';
export const NOME_USUARIO_DISPONIVEL_ERRO = 'NOME_USUARIO_DISPONIVEL_ERRO';

export const RESETAR_CONFIRMACAO_CADASTRO = 'RESETAR_CONFIRMACAO_CADASTRO';
