import RecuperacaoSenhaContainer from './components/RecuperacaoSenhaContainer';
import RedefinicaoSenhaContainer from './components/RedefinicaoSenhaContainer';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import * as selectors from './selectors';
import reducer from './reducer';

export default {
  RecuperacaoSenhaContainer,
  RedefinicaoSenhaContainer,
  actions,
  actionTypes,
  reducer,
  constants,
  selectors,
};
