"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _http_client = require("linear-react-commons/lib/middlewares/http_client");

var _permissionValidations = _interopRequireDefault(require("linear-react-components-ui/lib/permissionValidations"));

var _axios = _interopRequireDefault(require("axios"));

var _package = _interopRequireDefault(require("../../package.json"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var bases = {
  production: _package["default"].apiBaseURL,
  development: 'https://devt.erplinear.com.br'
};

var client = _axios["default"].create({
  baseURL: bases[process.env.NODE_ENV],
  responseType: 'json'
});

var httpClient = function httpClient() {
  var s = (0, _http_client.singleHttpClientMiddleware)(client, {
    authTokenName: 'auth_info',
    returnRejectedPromiseOnError: true,
    checkUserHasPermission: _permissionValidations["default"]
  }, {
    requestSuffix: '_REQUISICAO',
    errorSuffix: '_ERRO',
    successSuffix: '_SUCESSO'
  });
  return s;
};

var _default = httpClient;
exports["default"] = _default;