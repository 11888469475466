import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as projectNames from '@linear-front-end/infra/lib/projectNames';

const AuthLinks = ({
  login,
  recoverPassword,
  resendAccountConfirmation,
  t,
}) => {
  let links = [];

  if (login) {
    links = [...links,
      <Link to={`/${projectNames.AUTH}/login`} className="authlink" key="login">
        {t('autenticacao:links.login')}
      </Link>,
    ];
  }

  if (recoverPassword) {
    links = [...links,
      <Link to={`/${projectNames.AUTH}/recuperar-senha`} className="authlink" key="recuperar-senha">
        {t('autenticacao:links.recuperarSenha')}
      </Link>,
    ];
  }

  if (resendAccountConfirmation) {
    links = [...links,
      <Link to={`/${projectNames.AUTH}/reenvio-confirmacao-cadastro`} className="authlink" key="reenvio-confirmacao-cadastro">
        {t('autenticacao:links.reenviarConfirmacaoCadastro')}
      </Link>,
    ];
  }

  return <div className="linkscontainer">{links}</div>;
};

AuthLinks.propTypes = {
  t: PropTypes.func.isRequired,
  login: PropTypes.bool,
  recoverPassword: PropTypes.bool,
  resendAccountConfirmation: PropTypes.bool,
};

AuthLinks.defaultProps = {
  login: false,
  recoverPassword: false,
  resendAccountConfirmation: false,
};

export default AuthLinks;
