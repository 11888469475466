import React, { useState, useRef } from 'react';
import { SuccessButton } from 'linear-react-components-ui/lib/buttons';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form, { Field } from 'linear-react-components-ui/lib/form';
import TextField from 'linear-react-components-ui/lib/inputs/text';
import * as validators from '@linear-front-end/infra/lib/fieldValidators';
import { AuthLinks, PasswordStrength } from '../../components';
import { confirmarCadastro, verificarNomeUsuarioDisponivel } from '../actions';
import { getWaiting, getCheckingUserName, getUserNameAvailable } from '../selectors';
import login from '../../login';

const ConfirmacaoCadastroContainer = (props) => {
  const accountInfo = useRef(null);
  const { token } = props.match.params;

  const paserJwtToken = () => {
    const jwtbase64 = token.split('.')[1];
    // Replace abaixo resolve caracteres do token JWT não reconhecido pelo encode base64
    const base64 = jwtbase64.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = atob(base64);

    accountInfo.current = JSON.parse(jsonPayload);
    const usr = '_usr';
    accountInfo.current = { ...accountInfo.current, user: JSON.parse(accountInfo.current[usr]) };
    return accountInfo.current.user.userName;
  };

  const { t, isWaiting, isCheckingUserName, userNameAvailable } = props;
  const [formValido, setFormValido] = useState(false);
  const [handlerSubmit, setHandlerSubmit] = useState(false);
  const [novaData, setNovaData] = useState({});
  const [data, setData] = useState({
    token,
    nomeUsuario: paserJwtToken(),
    senha: '',
    confirmacaoSenha: '',
  });

  const onFieldChange = (e) => {
    novaData[e.target.name] = e.target.value;
    setData({ ...novaData });
  };

  const onSubmit = () => {
    const { nomeUsuario, senha } = data;
    props.confirmarCadastro(data)
      .then(() => {
        props.login({
          cnpj: accountInfo.current.user.cnpj,
          login: nomeUsuario,
          senha,
        }).then(() => {
          login.makeRedirection(accountInfo.current.user.id, props.match);
        });
      });
  };

  const getUserNameValidation = () => {
    const { nomeUsuario } = data;
    if (nomeUsuario === '') {
      return <p />;
    }
    if (isCheckingUserName) {
      return (
        <p className="username">
          {t('autenticacao:confirmacaoCadastro.verficandoNomeUsuario')}
        </p>
      );
    }

    return (
      <p className={`username ${userNameAvailable ? '-available' : '-unavailable'}`}>
        {userNameAvailable ?
          t('autenticacao:confirmacaoCadastro.nomeUsuarioValido') :
          t('autenticacao:confirmacaoCadastro.nomeUsuarioInvalido')}
        {isCheckingUserName ? 'verificando' : ''}
      </p>
    );
  };

  const verificarNomeUsuario = (nomeUsuario) => {
    const { cnpj, id } = accountInfo.current.user;
    const payload = { cnpj, nomeUsuario, idUsuario: id };
    if (nomeUsuario && nomeUsuario.length >= 5) {
      props.verificarNomeUsuarioDisponivel(payload);
    }
  };

  return (
    <Form
      customClass="confirmation-form"
      dataSource={data}
      onSubmit={(jsonData) => { onSubmit(jsonData); }}
      handlerSubmit={handler => setHandlerSubmit(() => (handler))}
      onValidateForm={(valido) => { setFormValido(valido); }}>
      <h1 className="title">{t('autenticacao:confirmacaoCadastro.titulo')}</h1>
      <Field
        name="nomeUsuario"
        component={TextField}
        label={t('autenticacao:confirmacaoCadastro.campos.usuario')}
        iconName="user"
        autoFocus
        onBlur={(e) => { verificarNomeUsuario(e.target.value); }}
        onChange={(e) => { setNovaData(e.target.value); }}
        handleFieldChange={onFieldChange}
        validators={[validators.required, validators.minLength(5), validators.maxLength(40)]} />
      {getUserNameValidation()}
      <Field
        name="senha"
        component={TextField}
        label={t('commons:labels.senha')}
        hint={t('commons:hints:regrasSenhaSegura')}
        type="password"
        iconName="key"
        onChange={(e) => { setNovaData(e.target.value); }}
        handleFieldChange={onFieldChange}
        validators={[validators.required, validators.minLength(6), validators.maxLength(15)]} />
      <Field
        name="confirmacaoSenha"
        component={TextField}
        label={t('commons:labels.confirmeSenha')}
        type="password"
        iconName="key"
        onChange={(e) => { setNovaData(e.target.value); }}
        handleFieldChange={onFieldChange}
        validators={[validators.required, validators.validatePassword(data.senha)]} />
      <PasswordStrength password={data.senha} t={t} />
      <SuccessButton
        disabled={!formValido || isWaiting}
        isLoading={isWaiting}
        label={t('commons:buttons.confirmar')}
        onClick={() => { handlerSubmit(); }} />
      <AuthLinks t={t} login resendAccountConfirmation />
    </Form>
  );
};

ConfirmacaoCadastroContainer.propTypes = {
  t: PropTypes.func.isRequired,
  confirmarCadastro: PropTypes.func.isRequired,
  verificarNomeUsuarioDisponivel: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isWaiting: PropTypes.bool.isRequired,
  isCheckingUserName: PropTypes.bool.isRequired,
  userNameAvailable: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isWaiting: getWaiting(state),
  isCheckingUserName: getCheckingUserName(state),
  userNameAvailable: getUserNameAvailable(state),
});

const connectRedux = connect(
  mapStateToProps,
  {
    confirmarCadastro,
    verificarNomeUsuarioDisponivel,
    login: login.actions.login,
  },
);
export default connectRedux(translate(['commons', 'autenticacao'])(ConfirmacaoCadastroContainer));
