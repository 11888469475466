import * as actionTypes from './actionTypes';

export function recuperarSenha(data) {
  return {
    type: actionTypes.RECUPERAR_SENHA,
    payload: {
      request: {
        data,
        url: 'seguranca/autenticacao/senha/nova',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'autenticacao',
        success: {
          key: 'recuperacaoSenha.mensagens.httpSucesso',
        },
        failure: {
          key: 'recuperacaoSenha.mensagens.httpFalha',
        },
      },
    },
    anonymous: true,
  };
}

export function redefinirSenha(data) {
  return {
    type: actionTypes.REDEFINIR_SENHA,
    payload: {
      request: {
        data,
        url: '/seguranca/autenticacao/senha/redefinir',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'autenticacao',
        success: {
          key: 'redefinicaoSenha.mensagens.httpSucesso',
        },
        failure: {
          key: 'redefinicaoSenha.mensagens.httpFalha',
        },
      },
    },
    anonymous: true,
  };
}

export function resetState() {
  return {
    type: actionTypes.RESETAR_REDEFINICAO_SENHA,
  };
}
