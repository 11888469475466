import React from 'react';
import { render } from 'react-dom';
import appInicializer from '@linear-front-end/infra/lib/appInitializer';
import * as projectNames from '@linear-front-end/infra/lib/projectNames';
import Autenticacao from './modules/autenticacao';
import rootReducer from './rootReducer';

render(
  appInicializer(<Autenticacao projectName={projectNames.AUTH} />, rootReducer),
  document.getElementById('root'),
);
