export const RECUPERAR_SENHA = 'RECUPERAR_SENHA';
export const RECUPERAR_SENHA_REQUISICAO = 'RECUPERAR_SENHA_REQUISICAO';
export const RECUPERAR_SENHA_SUCESSO = 'RECUPERAR_SENHA_SUCESSO';
export const RECUPERAR_SENHA_ERRO = 'RECUPERAR_SENHA_ERRO';

export const REDEFINIR_SENHA = 'REDEFINIR_SENHA';
export const REDEFINIR_SENHA_REQUISICAO = 'REDEFINIR_SENHA_REQUISICAO';
export const REDEFINIR_SENHA_SUCESSO = 'REDEFINIR_SENHA_SUCESSO';
export const REDEFINIR_SENHA_ERRO = 'REDEFINIR_SENHA_ERRO';

export const RESETAR_REDEFINICAO_SENHA = 'RESETAR_REDEFINICAO_SENHA';
