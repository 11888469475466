import React from 'react';
import TextField from 'linear-react-components-ui/lib/inputs/text';
import { CnpjField } from 'linear-react-components-ui/lib/inputs/mask';
import Icon from 'linear-react-components-ui/lib/icons';
import PropTypes from 'prop-types';

const Field = (props) => {
  if (props.mask) {
    return (
      <CnpjField
        {...props}
        customClass="loginfield"
        customClassForWrapper="wrapper"
        customClassForInputContent="input"
        labelUppercase />);
  }
  return (
    <TextField
      {...props}
      customClass="loginfield"
      customClassForWrapper="wrapper"
      customClassForInputContent="input"
      labelUppercase
      leftElements={
        props.iconName &&
        [<Icon
          name={props.iconName}
          key={`auth-${props.iconName}`}
          size={16}
          customClass="input-icon-color" />,
        ]
      }
      onChange={props.handleFieldChange ? (e) => { props.handleFieldChange(e); } : undefined} />);
};

Field.propTypes = {
  mask: PropTypes.bool,
  iconName: PropTypes.string,
  handleFieldChange: PropTypes.func,
};

Field.defaultProps = {
  iconName: undefined,
  mask: false,
  handleFieldChange: undefined,
};

export default Field;
