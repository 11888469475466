import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { SuccessButton } from 'linear-react-components-ui/lib/buttons';
import Form, { Field } from 'linear-react-components-ui/lib/form';
import TextField from 'linear-react-components-ui/lib/inputs/text';
import { getFrontBaseUrl } from '@linear-front-end/infra/lib/frontUrls';
import * as validators from '@linear-front-end/infra/lib/fieldValidators';
import * as projectNames from '@linear-front-end/infra/lib/projectNames';
import { reenviarConfirmacaoCadastro, resetState } from '../actions';
import { AuthLinks } from '../../components';
import { getWaiting } from '../selectors';

const ReenvioConfirmacaoCadastroContainer = (props) => {
  const { t, isWaiting } = props;
  const [reenviado] = useState(false);
  const [formValido, setFormValido] = useState(false);
  const [handlerSubmit, setHandlerSubmit] = useState(undefined);
  const [data] = useState({
    email: '',
    url: `${getFrontBaseUrl()[projectNames.AUTH]}autenticacao/confirmacao-cadastro/`,
  });

  useEffect(() => () => { props.resetState(); }, []);

  const onSubmit = () => {
    props.reenviarConfirmacaoCadastro(data);
  };

  return (
    <Form
      customClass="recover-form"
      dataSource={data}
      onSubmit={(jsonData) => { onSubmit(jsonData); }}
      onValidateForm={(valido) => { setFormValido(valido); }}
      handlerSubmit={handler => setHandlerSubmit(() => (handler))}>
      <h1 className="title">{t('autenticacao:reenvioConfirmacaoCadastro.titulo')}</h1>
      {!reenviado ? (
        <Fragment>
          <Field
            name="email"
            component={TextField}
            label={t('commons:labels.email')}
            iconName="arroba"
            hint={t('autenticacao:reenvioConfirmacaoCadastro.campos.emailHint')}
            type="email"
            autoFocus
            disabled={isWaiting}
            validators={[validators.required, validators.email]} />
          <SuccessButton
            disabled={!formValido || isWaiting}
            isLoading={isWaiting}
            label={t('commons:buttons.confirmar')}
            onClick={() => { handlerSubmit(); }} />
        </Fragment>
      ) : (
        <p className="messageconfirmation">
          {t('autenticacao:reenvioConfirmacaoCadastro.mensagens.confirmacao')}
        </p>
      )}
      <AuthLinks t={t} login />
    </Form>
  );
};

ReenvioConfirmacaoCadastroContainer.propTypes = {
  reenviarConfirmacaoCadastro: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isWaiting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isWaiting: getWaiting(state),
});

const connectRedux = connect(mapStateToProps, { reenviarConfirmacaoCadastro, resetState });
export default connectRedux(translate(['commons', 'autenticacao'])(ReenvioConfirmacaoCadastroContainer));
