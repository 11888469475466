import * as actionTypes from './actionTypes';

const initialState = {
  waiting: false,
};

const recuperacaoSenha = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RECUPERAR_SENHA_REQUISICAO:
    case actionTypes.REDEFINIR_SENHA_REQUISICAO:
      return {
        waiting: true,
      };
    case actionTypes.RECUPERAR_SENHA_SUCESSO:
    case actionTypes.REDEFINIR_SENHA_SUCESSO:
      return {
        waiting: false,
      };
    case actionTypes.RECUPERAR_SENHA_ERRO:
    case actionTypes.REDEFINIR_SENHA_ERRO:
    case actionTypes.RESETAR_REDEFINICAO_SENHA:
      return initialState;
    default:
      return state;
  }
};

export default recuperacaoSenha;
