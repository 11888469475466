"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.projectLabel = exports.paths = exports.COMPRAS = exports.VENDAS = exports.FISCAL = exports.FINANCEIRO = exports.PRODUTOS = exports.DOCUMENTOS_ELETRONICOS = exports.TERCEIROS = exports.ADMINISTRATIVO = exports.AUTH = exports.ERP = void 0;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ERP = 'app';
exports.ERP = ERP;
var AUTH = 'autenticacao';
exports.AUTH = AUTH;
var ADMINISTRATIVO = 'administrativo';
exports.ADMINISTRATIVO = ADMINISTRATIVO;
var TERCEIROS = 'terceiros';
exports.TERCEIROS = TERCEIROS;
var DOCUMENTOS_ELETRONICOS = 'documentos_eletronicos';
exports.DOCUMENTOS_ELETRONICOS = DOCUMENTOS_ELETRONICOS;
var PRODUTOS = 'produtos';
exports.PRODUTOS = PRODUTOS;
var FINANCEIRO = 'financeiro';
exports.FINANCEIRO = FINANCEIRO;
var FISCAL = 'fiscal';
exports.FISCAL = FISCAL;
var VENDAS = 'vendas';
exports.VENDAS = VENDAS;
var COMPRAS = 'compras';
exports.COMPRAS = COMPRAS;

var paths = function paths(projectName) {
  var _obj;

  var obj = (_obj = {}, _defineProperty(_obj, ERP, "".concat(ERP, "/")), _defineProperty(_obj, ADMINISTRATIVO, "".concat(ADMINISTRATIVO, "/")), _defineProperty(_obj, TERCEIROS, "".concat(TERCEIROS, "/")), _defineProperty(_obj, DOCUMENTOS_ELETRONICOS, "".concat(DOCUMENTOS_ELETRONICOS, "/")), _defineProperty(_obj, PRODUTOS, "".concat(PRODUTOS, "/")), _defineProperty(_obj, FINANCEIRO, "".concat(FINANCEIRO, "/")), _defineProperty(_obj, FISCAL, "".concat(FISCAL, "/")), _defineProperty(_obj, VENDAS, "".concat(VENDAS, "/")), _defineProperty(_obj, COMPRAS, "".concat(COMPRAS, "/")), _obj);
  return obj[projectName];
};

exports.paths = paths;

var projectLabel = function projectLabel(projectName) {
  var _labels;

  var labels = (_labels = {}, _defineProperty(_labels, ERP, 'Sistema de Gestão Linear'), _defineProperty(_labels, ADMINISTRATIVO, 'Módulo Administrativo Linear'), _defineProperty(_labels, TERCEIROS, 'Aplicações de Terceiros'), _defineProperty(_labels, DOCUMENTOS_ELETRONICOS, 'Documentos Eletrônicos'), _defineProperty(_labels, PRODUTOS, 'Produtos'), _defineProperty(_labels, FINANCEIRO, 'Financeiro'), _defineProperty(_labels, FISCAL, 'Fiscal'), _defineProperty(_labels, VENDAS, 'Vendas'), _defineProperty(_labels, COMPRAS, 'Compras'), _labels);
  return labels[projectName];
};

exports.projectLabel = projectLabel;