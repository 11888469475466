"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _configureStore = _interopRequireDefault(require("./configureStore.dev"));

var _configureStore2 = _interopRequireDefault(require("./configureStore.prod"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var stores = {
  development: _configureStore["default"],
  production: _configureStore2["default"]
};
/*
let store;
if (process.env.NODE_ENV === 'production') {
  store = storeProd;
} else {
  store = storeDev;
} */

var _default = stores[process.env.NODE_ENV];
exports["default"] = _default;