export const LOGIN = 'LOGIN';
export const LOGIN_REQUISICAO = 'LOGIN_REQUISICAO';
export const LOGIN_SUCESSO = 'LOGIN_SUCESSO';
export const LOGIN_ERRO = 'LOGIN_ERRO';

export const LOGOFF = 'LOGOFF';
export const LOGOFF_REQUISICAO = 'LOGOFF_REQUISICAO';
export const LOGOFF_SUCESSO = 'LOGOFF_SUCESSO';
export const LOGOFF_ERRO = 'LOGOFF_ERRO';

export const UNAUTHORIZED = 'UNAUTHORIZED';
