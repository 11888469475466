import { getCookie, setCookie } from 'linear-react-commons/lib/utils/web_storage';
import { getDefaultRedirectURL } from '@linear-front-end/infra/lib/frontUrls';
import * as constants from './constants';

const getRedirect = (idUsuario, match) => {
  let jsonObj;
  const { redirect, service } = match.params;
  if (redirect && redirect !== '') return { service, redirect };

  const cookie = getCookie(constants.LAST_REDIRECTED_URL);
  if (cookie && cookie !== '') {
    jsonObj = JSON.parse(cookie)[idUsuario];
    if (jsonObj) {
      return jsonObj[jsonObj.length - 1];
    }
  }
  return getDefaultRedirectURL();
};

const saveLastRedirectURL = (idUsuario, obj) => {
  if (!obj) return;
  let cookieJson;
  const cookieValue = getCookie(constants.LAST_REDIRECTED_URL);
  if (cookieValue) {
    cookieJson = JSON.parse(cookieValue);
    let redirects = cookieJson[idUsuario];
    redirects = redirects || [];
    redirects = redirects.filter(item => item.service !== obj.service);
    redirects = [...redirects, obj];
    cookieJson = { ...cookieJson, [idUsuario]: redirects };
  } else {
    cookieJson = { [idUsuario]: [obj] };
  }
  setCookie(constants.LAST_REDIRECTED_URL, JSON.stringify(cookieJson));
};

const makeRedirection = (idUsuario, reactRouterMatch) => {
  const obj = getRedirect(idUsuario, reactRouterMatch);
  saveLastRedirectURL(idUsuario, obj);
  window.location.href = window.atob(obj.redirect);
};

export default makeRedirection;
