import React from 'react';

const FormHeader = (tipoUsuarioSelecionado, aoSelecionarTipoUsuario, t) => (
  <div className="login-box">
    <h1 className="title">
      {t('autenticacao:login.tipoUsuario.titulo')}
      |
      <small className="return-types">
        {t(`autenticacao:login.tipoUsuario.label${tipoUsuarioSelecionado}`)}
      </small>
      <button
        type="button"
        className="return-types-action"
        onClick={() => { aoSelecionarTipoUsuario(''); }}>
        {t('autenticacao:login.tipoUsuario.buttonTipo')}
      </button>
    </h1>
    <p className="alert">
      {t('autenticacao:login.tipoUsuario.alertaTipo')}
      <b className="alert-weight">
        {t(`autenticacao:login.tipoUsuario.alertaTipo${tipoUsuarioSelecionado}`)}
      </b>
    </p>
  </div>
);

export default FormHeader;
