import LoginContainer from './components/LoginContainer';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import reducer from './reducer';
import * as selectors from './selectors';
import makeRedirection from './makeRedirection';

export default {
  LoginContainer,
  actions,
  actionTypes,
  reducer,
  constants,
  selectors,
  makeRedirection,
};
