"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFrontBaseUrl = getFrontBaseUrl;
exports.getEntryUrl = getEntryUrl;
exports.getHomeUrl = getHomeUrl;
exports.getUrlConfirmacaoCadastro = exports.getDefaultRedirectURL = exports.formatUrlCompras = exports.formatUrlVendas = exports.formatUrlFiscal = exports.formatUrlFinanceiro = exports.formatUrlProdutos = exports.formatUrlTerceiros = exports.formatUrlAuth = exports.formatUrlERP = exports.formatUrlAdministrativo = void 0;

var projectNames = _interopRequireWildcard(require("./projectNames"));

var _package = _interopRequireDefault(require("../package.json"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var developmentUrls = {};
developmentUrls[projectNames.AUTH] = 'http://localhost:3000/';
developmentUrls[projectNames.ERP] = 'http://localhost:3010/';
developmentUrls[projectNames.ADMINISTRATIVO] = 'http://localhost:3020/';
developmentUrls[projectNames.TERCEIROS] = 'http://localhost:3030/';
developmentUrls[projectNames.PRODUTOS] = 'http://localhost:3040/';
developmentUrls[projectNames.FINANCEIRO] = 'http://localhost:3050/';
developmentUrls[projectNames.FISCAL] = 'http://localhost:3060/';
developmentUrls[projectNames.VENDAS] = 'http://localhost:3070/';
developmentUrls[projectNames.COMPRAS] = 'http://localhost:3080/';
var productionUrls = {};
productionUrls[projectNames.AUTH] = _package["default"].frontBaseURL;
productionUrls[projectNames.ERP] = _package["default"].frontBaseURL;
productionUrls[projectNames.ADMINISTRATIVO] = _package["default"].frontBaseURL;
productionUrls[projectNames.TERCEIROS] = _package["default"].frontBaseURL;
productionUrls[projectNames.PRODUTOS] = _package["default"].frontBaseURL;
productionUrls[projectNames.FINANCEIRO] = _package["default"].frontBaseURL;
productionUrls[projectNames.FISCAL] = _package["default"].frontBaseURL;
productionUrls[projectNames.VENDAS] = _package["default"].frontBaseURL;
productionUrls[projectNames.COMPRAS] = _package["default"].frontBaseURL;

function getFrontBaseUrl() {
  return {
    development: _objectSpread({}, developmentUrls),
    production: _objectSpread({}, productionUrls)
  }[process.env.NODE_ENV];
}

function getEntryUrl(projectName) {
  var baseUrl = getFrontBaseUrl()[projectName];
  var entryUrls = {};
  entryUrls[projectNames.ERP] = "".concat(baseUrl).concat(projectNames.ERP, "/seguranca");
  entryUrls[projectNames.AUTH] = "".concat(baseUrl).concat(projectNames.AUTH, "/login");
  entryUrls[projectNames.ADMINISTRATIVO] = "".concat(baseUrl).concat(projectNames.ADMINISTRATIVO, "/cadastros");
  entryUrls[projectNames.TERCEIROS] = "".concat(baseUrl).concat(projectNames.TERCEIROS, "/cotacao");
  entryUrls[projectNames.PRODUTOS] = "".concat(baseUrl).concat(projectNames.PRODUTOS, "/cadastros");
  entryUrls[projectNames.FINANCEIRO] = "".concat(baseUrl).concat(projectNames.FINANCEIRO, "/cadastros");
  entryUrls[projectNames.FISCAL] = "".concat(baseUrl).concat(projectNames.FISCAL, "/cadastros");
  entryUrls[projectNames.VENDAS] = "".concat(baseUrl).concat(projectNames.VENDAS, "/cadastros");
  entryUrls[projectNames.COMPRAS] = "".concat(baseUrl).concat(projectNames.COMPRAS, "/cadastros");
  return entryUrls[projectName];
}

function getHomeUrl(projectName) {
  return getFrontBaseUrl()[projectName];
}

var formatUrlAdministrativo = function formatUrlAdministrativo(url) {
  return "/".concat(projectNames.ADMINISTRATIVO, "/").concat(url);
};

exports.formatUrlAdministrativo = formatUrlAdministrativo;

var formatUrlERP = function formatUrlERP(url) {
  return "/".concat(projectNames.ERP, "/").concat(url);
};

exports.formatUrlERP = formatUrlERP;

var formatUrlAuth = function formatUrlAuth(url) {
  return "/".concat(projectNames.AUTH, "/").concat(url);
};

exports.formatUrlAuth = formatUrlAuth;

var formatUrlTerceiros = function formatUrlTerceiros(url) {
  return "/".concat(projectNames.TERCEIROS, "/").concat(url);
};

exports.formatUrlTerceiros = formatUrlTerceiros;

var formatUrlProdutos = function formatUrlProdutos(url) {
  return "/".concat(projectNames.PRODUTOS, "/").concat(url);
};

exports.formatUrlProdutos = formatUrlProdutos;

var formatUrlFinanceiro = function formatUrlFinanceiro(url) {
  return "/".concat(projectNames.FINANCEIRO, "/").concat(url);
};

exports.formatUrlFinanceiro = formatUrlFinanceiro;

var formatUrlFiscal = function formatUrlFiscal(url) {
  return "/".concat(projectNames.FISCAL, "/").concat(url);
};

exports.formatUrlFiscal = formatUrlFiscal;

var formatUrlVendas = function formatUrlVendas(url) {
  return "/".concat(projectNames.VENDAS, "/").concat(url);
};

exports.formatUrlVendas = formatUrlVendas;

var formatUrlCompras = function formatUrlCompras(url) {
  return "/".concat(projectNames.COMPRAS, "/").concat(url);
};

exports.formatUrlCompras = formatUrlCompras;

var getDefaultRedirectURL = function getDefaultRedirectURL() {
  return {
    service: projectNames.ERP,
    redirect: window.btoa(getEntryUrl(projectNames.ERP))
  };
};

exports.getDefaultRedirectURL = getDefaultRedirectURL;

var getUrlConfirmacaoCadastro = function getUrlConfirmacaoCadastro() {
  var url = "".concat(getFrontBaseUrl()[projectNames.AUTH], "autenticacao/confirmacao-cadastro/");
  return url;
};

exports.getUrlConfirmacaoCadastro = getUrlConfirmacaoCadastro;