export const NAME = 'login';
export const AUTH_COOKIE_NAME = 'auth_info';
export const TYPE_USER_COOKIE = 'type_user';
export const CNPJ_LOGIN = 'cnpj_login';
export const LAST_REDIRECTED_URL = 'last_redirected_url';
export const TYPE_USER = {
  colaborador: 'Colaborador',
  fornecedor: 'Fornecedor',
  associado: 'Associado',
};
