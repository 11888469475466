import * as actionTypes from './actionTypes';

const initialState = {
  waiting: false,
  checkingUserName: false,
  userNameAvailable: true,
};

const confirmacaoCadastro = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONFIRMAR_CADASTRO_REQUISICAO:
    case actionTypes.REENVIAR_CONFIRMACAO_CADASTRO_REQUISICAO:
      return {
        ...state, waiting: true,
      };
    case actionTypes.CONFIRMAR_CADASTRO_SUCESSO:
    case actionTypes.REENVIAR_CONFIRMACAO_CADASTRO_SUCESSO:
      return {
        ...state, waiting: false,
      };
    case actionTypes.CONFIRMAR_CADASTRO_ERRO:
    case actionTypes.REENVIAR_CONFIRMACAO_CADASTRO_ERRO:
      return {
        ...state, waiting: false,
      };
    case actionTypes.NOME_USUARIO_DISPONIVEL_REQUISICAO:
      return {
        ...state, checkingUserName: true, userNameAvailable: false,
      };
    case actionTypes.NOME_USUARIO_DISPONIVEL_SUCESSO:
      return {
        ...state, checkingUserName: false, userNameAvailable: action.payload.data.content,
      };
    case actionTypes.RESETAR_CONFIRMACAO_CADASTRO:
      return initialState;
    default:
      return state;
  }
};

export default confirmacaoCadastro;
