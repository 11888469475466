import * as actionTypes from './actionTypes';

export function login(data) {
  return {
    type: actionTypes.LOGIN,
    payload: {
      request: {
        data,
        url: 'seguranca/autenticacao/token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
      },
      feedbackMessages: {
        i18nNS: 'autenticacao',
        success: {
          key: 'login.mensagens.httpSucesso',
        },
        failure: {
          alertOptions: { type: 'danger', position: 'centerTop' },
          key: 'login.mensagens.httpFalha',
        },
      },
    },
    anonymous: true,
  };
}

export function logoff() {
  return {
    type: actionTypes.LOGOFF_SUCESSO,
  };
}
/* payload: {
      request: {
        data: {},
        url: '/seguranca/autenticacao/logoff',
        method: 'POST',
      },
}, */
