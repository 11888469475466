import React, { useState, useEffect } from 'react';
import Icon from 'linear-react-components-ui/lib/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { SuccessButton } from 'linear-react-components-ui/lib/buttons';
import Form, { Field } from 'linear-react-components-ui/lib/form';
import TextField from 'linear-react-components-ui/lib/inputs/text';
import * as validators from '@linear-front-end/infra/lib/fieldValidators';
import { redefinirSenha, resetState } from '../actions';
import { AuthLinks } from '../../components';
import { getWaiting } from '../selectors';
import PasswordStrength from '../../components/PasswordStrength';

const RedefinicaoSenhaContainer = (props) => {
  const { t, isWaiting, history } = props;
  const [formValido, setFormValido] = useState(false);
  const [handlerSubmit, setHandlerSubmit] = useState(false);
  const [data, setData] = useState({
    senha: '',
    confirmacaoSenha: '',
    codigoVerificacao: props.match.params.token,
  });

  useEffect(() => () => { props.resetState(); }, []);

  const onFieldChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const onSubmit = () => {
    props.redefinirSenha(data)
      .then(() => {
        history.push('/autenticacao/login');
      });
  };

  return (
    <Form
      customClass="reset-password-form"
      dataSource={data}
      onSubmit={(jsonData) => { onSubmit(jsonData); }}
      handlerSubmit={handler => setHandlerSubmit(() => (handler))}
      onValidateForm={(valido) => { setFormValido(valido); }}>
      <h1 className="title">{t('autenticacao:redefinicaoSenha.titulo')}</h1>
      <Field
        name="senha"
        component={TextField}
        label={t('commons:labels.senha')}
        autoFocus
        type="password"
        hint={t('commons:hints:regrasSenhaSegura')}
        leftElements={[<Icon name="key" key="campoSenha" size={24} customClass="input-icon-color" />]}
        onChange={onFieldChange}
        validators={[validators.required, validators.minLength(6), validators.maxLength(15)]} />
      <Field
        name="confirmacaoSenha"
        component={TextField}
        label={t('commons:labels.confirmeSenha')}
        type="password"
        hint={t('commons:hints:confirmacaoSenha')}
        leftElements={[<Icon name="key" key="campoConfirmacaoSenha" size={24} customClass="input-icon-color" />]}
        validators={[validators.required, validators.validatePassword(data.senha)]} />
      <PasswordStrength password={data.senha} t={t} />
      <SuccessButton
        disabled={!formValido || isWaiting}
        isLoading={isWaiting}
        label={t('commons:buttons.confirmar')}
        onClick={() => { handlerSubmit(); }} />
      <AuthLinks t={t} login recoverPassword />
    </Form>
  );
};

RedefinicaoSenhaContainer.propTypes = {
  redefinirSenha: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isWaiting: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isWaiting: getWaiting(state),
});

const connectRedux = connect(mapStateToProps, { redefinirSenha, resetState });
export default connectRedux(translate(['commons', 'autenticacao'])(RedefinicaoSenhaContainer));
