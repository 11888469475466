"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocalData = exports.removeLocalData = exports.storageLocalData = exports.storageSessionData = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var storageSessionData = function storageSessionData(storageName, key, content) {
  var storage = JSON.parse(sessionStorage.getItem(storageName));

  if (!storage) {
    storage = _defineProperty({}, key, content);
  } else if (!storage[key]) {
    storage = _objectSpread(_objectSpread({}, storage), {}, _defineProperty({}, key, content));
  }

  sessionStorage.setItem(storageName, JSON.stringify(storage));
};

exports.storageSessionData = storageSessionData;

var storageLocalData = function storageLocalData(storageName, content) {
  var storage = JSON.parse(localStorage.getItem(storageName));

  if (!storage) {
    storage = content;
  } else {
    storage = _objectSpread(_objectSpread({}, storage), content);
  }

  localStorage.setItem(storageName, JSON.stringify(storage));
};

exports.storageLocalData = storageLocalData;

var removeLocalData = function removeLocalData(storageName) {
  return localStorage.removeItem(storageName);
};

exports.removeLocalData = removeLocalData;

var getLocalData = function getLocalData(storageName) {
  return localStorage.getItem(storageName);
};

exports.getLocalData = getLocalData;