"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _redux = require("redux");

var _reduxLogger = require("redux-logger");

var _createHttpClient = _interopRequireDefault(require("./createHttpClient"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// import rootReducer from '../../rootReducer';
var middlewares = [(0, _createHttpClient["default"])(), (0, _reduxLogger.createLogger)()];
/* eslint-disable no-underscore-dangle */

var configureStore = function configureStore(preloadedState, rootReducer) {
  var store = (0, _redux.createStore)(rootReducer, preloadedState, (0, _redux.compose)(_redux.applyMiddleware.apply(void 0, middlewares), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));
  /*
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../../rootReducer', () => {
      store.replaceReducer(rootReducer);
    });
  }
  */

  return store;
};
/* eslint-enable */


var _default = configureStore;
exports["default"] = _default;