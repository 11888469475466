import React, { useState, useEffect } from 'react';
import { getCookie, setCookie } from 'linear-react-commons/lib/utils/web_storage';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import withAlert from 'linear-react-components-ui/lib/alerts';
import { SuccessButton } from 'linear-react-components-ui/lib/buttons';
import Form, { Field } from 'linear-react-components-ui/lib/form';
import TextField from 'linear-react-components-ui/lib/inputs/text';
import * as validators from '@linear-front-end/infra/lib/fieldValidators';
import { removeLocalData } from '@linear-front-end/infra/lib/browser_storage';
import { UrlsAvatarKey } from '@linear-front-end/infra/lib/constants';
import { login } from '../actions';
import { AuthLinks } from '../../components';
import * as constants from '../constants';
import { getIsWaiting } from '../selectors';
import makeRedirection from '../makeRedirection';
import TipoUsuario from './TipoUsuario';
import FormHeader from './FormHeader';

const cnpjLogin = getCookie(constants.CNPJ_LOGIN);

const Login = (props) => {
  const { t, isWaiting, alert } = props;
  const [data] = useState({
    cnpj: cnpjLogin || '',
    login: '',
    senha: '',
  });
  const [formValido, setFormValido] = useState(false);
  const [handlerSubmit, setHandlerSubmit] = useState(false);
  const [
    tipoUsuarioSelecionado, setTipoUsuarioSelecionado,
  ] = useState(getCookie(constants.TYPE_USER_COOKIE));

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramTokenAusente = urlParams.get('tokenAusente');
    if (paramTokenAusente) {
      alert.default({
        options: { type: 'info', timeout: '5000', position: 'centerTop' },
        message: t('autenticacao:login.mensagens.tokenAusente'),
      });
    }
  }, []);

  const onSubmit = (jsonData) => {
    props.login(jsonData)
      .then((response) => {
        const { idUsuario } = response.payload.data.content;
        removeLocalData(UrlsAvatarKey);
        makeRedirection(idUsuario, props.match);
      });
  };

  const selecionarTipoUsuario = (tipoDeUsuarioSelecionado) => {
    setTipoUsuarioSelecionado(tipoDeUsuarioSelecionado);
    setCookie(constants.TYPE_USER_COOKIE, tipoDeUsuarioSelecionado);
  };

  const renderForm = () => (
    <Form
      customClass="form-login"
      dataSource={data}
      onSubmit={(jsonData) => { onSubmit(jsonData); }}
      handlerSubmit={handler => setHandlerSubmit(() => (handler))}
      onValidateForm={(valido) => { setFormValido(valido); }}>
      {FormHeader(tipoUsuarioSelecionado, selecionarTipoUsuario, t)}
      <Field
        name="cnpj"
        component={TextField}
        label={t('autenticacao:login.campos.empresaLogin')}
        hint={t('autenticacao:login.campos.empresaLoginHint')}
        iconName="store"
        autoFocus
        validators={[validators.required, validators.minLength(14), validators.maxLength(14)]} />
      <Field
        name="login"
        component={TextField}
        label={t('autenticacao:login.campos.usuarioEmail')}
        iconName="user"
        validators={validators.required} />
      <Field
        name="senha"
        component={TextField}
        label={t('labels.senha')}
        type="password"
        iconName="key"
        validators={[validators.required, validators.minLength(6)]} />
      <SuccessButton
        label={t('commons:buttons.confirmar')}
        disabled={!formValido || isWaiting}
        isLoading={isWaiting}
        onClick={() => { handlerSubmit(); }} />
      <AuthLinks t={t} recoverPassword resendAccountConfirmation />
    </Form>
  );

  return (
    !tipoUsuarioSelecionado ? (
      <TipoUsuario
        {...props}
        handlerSelecionarTipoUsuario={selecionarTipoUsuario} />
    ) :
      renderForm()
  );
};

const mapStateToProps = state => ({
  isWaiting: getIsWaiting(state),
});

Login.propTypes = {
  alert: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isWaiting: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

const connectRedux = connect(mapStateToProps, { login });
export default connectRedux(translate(['commons', 'autenticacao'])(withAlert(Login)));
