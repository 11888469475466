import { combineReducers } from 'redux';
import feedbackMessages from '@linear-front-end/infra/lib/feedback_messages';
import login from './modules/autenticacao/login';
import recuperacaoSenha from './modules/autenticacao/recuperacao_senha';
import confirmacaoCadastro from './modules/autenticacao/confirmacao_cadastro';

export default combineReducers({
  [login.constants.NAME]: login.reducer,
  [recuperacaoSenha.constants.NAME]: recuperacaoSenha.reducer,
  [confirmacaoCadastro.constants.NAME]: confirmacaoCadastro.reducer,
  [feedbackMessages.constants.NAME]: feedbackMessages.reducer,
});
