import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import * as projectNames from '@linear-front-end/infra/lib/projectNames';
import login from './login';
import RecuperacaoSenha from './recuperacao_senha';
import ConfirmacaoCadastro from './confirmacao_cadastro';

const AuthRoutes = (props) => {
  const { location } = props;
  const currentKey = location.pathname.split('/')[2] || '/';
  const timeout = {
    leave: 200, enter: 600, exit: 100, appear: 200,
  };
  const { LoginContainer } = login;
  const { RecuperacaoSenhaContainer, RedefinicaoSenhaContainer } = RecuperacaoSenha;
  const { ConfirmacaoCadastroContainer, ReenvioConfirmacaoCadastroContainer } = ConfirmacaoCadastro;
  return (
    <TransitionGroup component={null} className="auth-container">
      <CSSTransition key={currentKey} timeout={timeout} classNames="SlideIn" appear>
        <Switch location={location}>
          <Route exact path="/" component={LoginContainer} />
          <Route path={`/${projectNames.AUTH}/login`} component={LoginContainer} />
          <Route path={`/${projectNames.AUTH}/login/:service/:redirect`} component={LoginContainer} />
          <Route path={`/${projectNames.AUTH}/recuperar-senha`} component={RecuperacaoSenhaContainer} />
          <Route path={`/${projectNames.AUTH}/redefinir-senha/:token`} component={RedefinicaoSenhaContainer} />
          <Route path={`/${projectNames.AUTH}/reenvio-confirmacao-cadastro`} component={ReenvioConfirmacaoCadastroContainer} />
          <Route path={`/${projectNames.AUTH}/confirmacao-cadastro/:token`} component={ConfirmacaoCadastroContainer} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

AuthRoutes.propTypes = {
  location: PropTypes.object.isRequired,
  usuarioLogado: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  usuarioLogado: login.selectors.getIsLogged(state),
});

export default withRouter(connect(mapStateToProps)(AuthRoutes));
