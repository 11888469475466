import React from 'react';
import feedbackMessages from '@linear-front-end/infra/lib/feedback_messages';
import PropTypes from 'prop-types';
import bg from '@linear-front-end/assets/lib/images/auth_bg.jpg';
import AuthRoutes from './routes';
import './auth.scss';

const Autenticacao = ({ usuarioLogado }) => (
  <div className="auth-container">
    <feedbackMessages.FeedbackMessageContainer />
    <div className="background-overlay">
      <img src={bg} alt="background_img" />
    </div>
    <div className="about" />
    <div className="content">
      <AuthRoutes usuarioLogado={usuarioLogado} />
    </div>
  </div>
);

Autenticacao.propTypes = {
  usuarioLogado: PropTypes.bool,
};

Autenticacao.defaultProps = {
  usuarioLogado: undefined,
};

export default Autenticacao;
