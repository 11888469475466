import * as actionTypes from './actionTypes';

export function confirmarCadastro(data) {
  return {
    type: actionTypes.CONFIRMAR_CADASTRO,
    payload: {
      request: {
        data,
        url: 'seguranca/autenticacao/cadastro/confirmar',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'autenticacao',
        success: {
          key: 'confirmacaoCadastro.mensagens.httpSucesso',
        },
        failure: {
          key: 'confirmacaoCadastro.mensagens.httpFalha',
        },
      },
    },
    anonymous: true,
  };
}

export function reenviarConfirmacaoCadastro(data) {
  return {
    type: actionTypes.REENVIAR_CONFIRMACAO_CADASTRO,
    payload: {
      request: {
        data,
        url: 'seguranca/autenticacao/cadastro/notificar',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'autenticacao',
        success: {
          key: 'reenvioConfirmacaoCadastro.mensagens.httpSucesso',
        },
        failure: {
          key: 'reenvioConfirmacaoCadastro.mensagens.httpFalha',
        },
      },
    },
    anonymous: true,
  };
}

export function verificarNomeUsuarioDisponivel(data) {
  return {
    type: actionTypes.NOME_USUARIO_DISPONIVEL,
    payload: {
      request: {
        data,
        url: 'seguranca/autenticacao/cadastro/nome-usuario-disponivel',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'autenticacao',
        success: false,
        failure: {
          key: 'confirmacaoCadastro.mensagens.httpFalhaVerficacaoNomeUsuario',
        },
      },
    },
    anonymous: true,
  };
}

export function resetState() {
  return {
    type: actionTypes.RESETAR_CONFIRMACAO_CADASTRO,
  };
}
