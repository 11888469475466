import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TYPE_USER } from '../constants';

const TipoUsuario = ({ t, handlerSelecionarTipoUsuario }) => (
  <div className="type-box">
    <h1 className="title">{t('autenticacao:login.tipoUsuario.titulo')}</h1>
    <p className="description">{t('autenticacao:login.tipoUsuario.subTitulo')}</p>
    <ul className="button-container">
      {_.map(TYPE_USER, value => (
        <li className="button-item-list" key={value}>
          <button
            type="button"
            className="button-item"
            onClick={() => { handlerSelecionarTipoUsuario(value); }}>
            {t(`autenticacao:login.tipoUsuario.label${value}`)}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

TipoUsuario.propTypes = {
  t: PropTypes.func.isRequired,
  handlerSelecionarTipoUsuario: PropTypes.func.isRequired,
};

export default TipoUsuario;
