import { getCookie, removeCookie, setCookie } from 'linear-react-commons/lib/utils/web_storage';
import { storeUserAuthInfo } from 'linear-react-commons/lib/utils/auth_token';
import * as actionTypes from './actionTypes';
import * as constants from './constants';

const cookie = getCookie(constants.AUTH_COOKIE_NAME);

const initialState = {
  logged: !(cookie === null),
  waiting: false,
  infoLogin: cookie ? JSON.parse(cookie) : undefined,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUISICAO:
      return {
        logged: false,
        waiting: true,
      };
    case actionTypes.LOGIN_SUCESSO:
      storeUserAuthInfo(constants.AUTH_COOKIE_NAME, action.payload.data.content);
      setCookie(constants.CNPJ_LOGIN, action.meta.previousAction.payload.request.data.cnpj);
      return {
        infoLogin: action.payload.data.content,
        logged: true,
        waiting: false,
      };
    case actionTypes.LOGIN_ERRO:
    case actionTypes.UNAUTHORIZED:
      return {
        infoLogin: undefined,
        logged: false,
        waiting: false,
      };
    case actionTypes.LOGOFF_SUCESSO:
      removeCookie(constants.AUTH_COOKIE_NAME);
      return {
        infoLogin: undefined,
        logged: false,
      };
    default:
      return state;
  }
};

export default login;
