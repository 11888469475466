"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UrlsAvatarKey = exports.MEDIUM_SCREEN_SIZE = exports.SMALL_SCREEN_SIZE = void 0;
var SMALL_SCREEN_SIZE = 1200;
exports.SMALL_SCREEN_SIZE = SMALL_SCREEN_SIZE;
var MEDIUM_SCREEN_SIZE = 1400; // export const LARGE_SCREEN_SIZE = 1200;
// export const SMALL_SCREEN_SIZE = 1400;
// // export const MEDIUM_SCREEN_SIZE = 1200;
// export const LARGE_SCREEN_SIZE = 1200;

exports.MEDIUM_SCREEN_SIZE = MEDIUM_SCREEN_SIZE;
var UrlsAvatarKey = 'urls_avatar';
exports.UrlsAvatarKey = UrlsAvatarKey;